import * as React from 'react'

export const SvgContactUsChat = (props: React.SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="24"
    height="24"
    viewBox="0 0 24 24"
    {...props}
  >
    <path d="M14.1289062,16 L7.0019014,23 L7,16 L4,16 C3.44771525,16 3,15.5522847 3,15 L3,6 C3,5.44771525 3.44771525,5 4,5 L20,5 C20.5522847,5 21,5.44771525 21,6 L21,15 C21,15.5522847 20.5522847,16 20,16 L14.1289062,16 Z"></path>
  </svg>
)
export default SvgContactUsChat
